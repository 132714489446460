<template>
    <div>
        <c-modal titulo="Cancelar Compra" ref="modal">

            <div class="row padding-b1">
                <div class="col-12">
                    <p>Preencha os campos abaixo para registrar o cancelamento de uma compra.</p>
                </div>
            </div>


            <div class="row padding-b1">
                <div class="col-12">
                    <div class="form-floating">
                        <select class="form-select" id="floatingSelect" v-model="campos.colaborador" @change="seleciona_colaborador">
                            <option v-for="(usuario,usuario_i) in usuarios" :key="usuario_i" :value="usuario.ID">{{ usuario.Nome }}</option>
                        </select>
                        <label for="floatingSelect">Selecione um Colaborador</label>
                    </div>
                </div>
            </div>

            <div class="row padding-b1">
                <div class="col-12">
                    <div class="form-floating">
                        <select :disabled="!compras" class="form-select" id="floatingSelect"  v-model="campos.compra">
                            <option value="">Selecione uma compra</option>
                            <option v-for="(compra,compra_i) in compras" :key="compra_i" :value="compra.ID">{{ compra.DataHora | datahora }}{{ ' | ' }}{{ compra.ProdutoNome }} {{' - '}}{{  compra.SALDO | currency }}</option>
                        </select>
                        <label for="floatingSelect">Selecione Compra</label>
                    </div>
                </div>
            </div>

            <div class="row padding-b1">
                <div class="col-12">
                    <div class="form-floating">
                        <div class="form-floating">
                            <textarea  v-model="campos.motivo" :disabled="!compras || !campos.compra" class="form-control" ref="motivo" style="height: 100px"></textarea>
                            <label for="floatingTextarea2">Motivo do Cancelamento</label>
                        </div>
                    </div>
                </div>
            </div>

            

            <template v-slot:rodape>
                <button class="btn btn-primary" :disabled="!compras || !campos.compra || !campos.motivo" @click="confirmar">✅ Confirmar cancelamento</button>
                <button type="button" class="btn btn-secondary fechar" @click="fechar">Fechar</button>
            </template>

            
        </c-modal>

    </div>
</template>
<script>
export default {

    data: function(){
        return {
            usuarios: null,
            compras: '',
            campos: {
                colaborador: null,
                compra: null,
                motivo: null
            }
        }
    },

    mounted: function(){



    },

    methods: {

        async seleciona_colaborador(){

            if(!this.campos.colaborador){
                return;
            }

            //carrega compras do colaborador
            let ret = await this.$sdk.get('/movimentacoes', {usuario : this.campos.colaborador});

            console.log('Carregando MOVIMENTAÇÕES', ret);

            if(ret.status == 200)
                this.compras = ret.data;
            else
                alert('Nenhuma compra deste colaborador.');
        },

        seleciona_compra(){

            if(!this.campos.compra){
                return;
            }

            this.$refs.motivo.focus();

        },
        
        async confirmar(){
            
            let ret = await this.$sdk.post('/cancelar', {
                movimentacao: this.campos.compra,
                motivo: this.campos.motivo
            });

            if(ret.status == 200){
                
                // mensagem de erro
                this.$swal.fire({
                    icon: 'success',
                    title: '✅ Cancelamento da compra concluído! ',
                    text: 'Deu tudo certo a compra foi cancelada.'
                })

                this.$emit('cancelou');
                
            } else {
                
                // mensagem de erro
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops! Tivemos um probleminha 🙄',
                    text: ret.mensagem
                })

            }

            this.fechar();
            

        },

        async load(){

            this.compras = null;
            this.campos.colaborador = '';
            this.campos.compra = '';
            this.campos.motivo = '';

            let ret = await this.$sdk.get('/usuarios');

            console.log('Carregando COLABORADORES', ret);

            if(ret.status == 200)
                this.usuarios = ret.data;
            else
                this.usuarios = null;

        },

        abrir(){
            this.load();
            console.log('USUARIOS', this.usuarios);
            this.$refs.modal.abrir();
        },

        fechar(){
            this.$refs.modal.fechar();
        }
    }

}
</script>