<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'App',   
    metaInfo: {
      title: 'Honest Bar / Tempo Brasil',
      titleTemplate: '',
      meta: [{
            name: 'robots',
            content: 'index, follow',
        }]
    }  ,
    
    computed: {
        isAuthenticated() {
            return this.$store.state.auth.estaLogado;
        }
    },

    methods: {

        ...mapActions('auth', {
            store_login: 'login'
        }),

        simLogado(){
            //this.$router.push(this.$route.path);
        },

        naoLogado(msg){
            this.$router.push({ name: 'login', query: { msg }});
        }

    },

    async created() {

        //verifica se o token tá no cash
        if(localStorage.getItem('rocket_token')){

           let token = localStorage.getItem('rocket_token');

           this.$sdk.token = token;
            let ret = await this.$sdk.get('/login');
            console.log('Carregou do TOKEN', ret);

            if(ret.status == 200){
                this.store_login({ usuario: ret.data.usuario, token: ret.data.token})
                this.simLogado();
            } else {
                this.naoLogado(ret.mensagem);
            }

        } else {
            this.naoLogado();
        }
        
        // Verifica se o usuário está autenticado assim que o componente é criado
        /**
        if (this.estaLogado) {
            this.$router.push(this.$route.path);
        } else {
            if(this.$route.name !== 'login'){
                this.$router.push('/login');
            }
        }

        **/


        // verifica se estou dentro de uma pagina do sistema ou do frot e adiciona uma class no body
        if (this.$route.path.startsWith("/sistema")) {
            document.body.classList.add("sistema");
            document.body.classList.remove("site");
        } else {
            document.body.classList.add("site");
            document.body.classList.remove("sistema");
        }
    },



}
</script>

<style lang="scss">
    @import "@sass/init.scss";
</style>