<template>
  <div>
    <c-modal titulo="Relatório de Consumo" ref="modal" @submit="gerar">
      <div class="mb-3">
        <label for="nome" class="col-form-label">Selecione um mês:</label>
        <select
          class="form-select form-select-sm"
          aria-label=".form-select-sm"
          required
          v-model="mes_selecionado"
        >
          <option v-for="(mes, mes_i) in meses" :key="mes_i" :value="mes.valor">
            {{ mes.legenda }}
          </option>
        </select>
      </div>

      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary fechar" @click="fechar">
          Cancelar
        </button>
        <button type="submit" class="btn btn-primary">Gerar PDF</button>
      </template>
    </c-modal>
  </div>
</template>
<script>
//import { Modal } from 'bootstrap';

export default {
  data: function () {
    return {
      meses: [],
      mes_selecionado: "",
    };
  },

  mounted: function () {
    this.load();
  },

  methods: {
    gerar() {
      window.open(
        "https://honestbar.agenciatempobrasil.com.br/api/relatorios" +
          this.mes_selecionado
      );
      this.fechar();
    },

    abrir() {
      this.$refs.modal.abrir();
    },

    fechar() {
      this.$refs.modal.fechar();
    },

    load() {
      const hoje = new Date();
      let meses = [];
      let mes = new Date(2023, 0, 1); // Janeiro é 0 em JavaScript

      while (hoje >= mes) {
        meses.push({
          valor: "?ano=" + mes.getFullYear() + "&mes=" + (mes.getMonth() + 1),
          legenda:
            (mes.getMonth()+1).toString().padStart(2, "0") +
            "/" +
            mes.getFullYear(),
        });

        if (mes.getMonth() == 11) {
          // Dezembro é 11 em JavaScript
          mes.setFullYear(mes.getFullYear() + 1);
          mes.setMonth(0); // Janeiro
        } else {
          mes.setMonth(mes.getMonth() + 1);
        }
      }

      this.meses = meses;
    },
  },
};
</script>

<style lang="scss" scoped>
.editar {
  cursor: pointer;
}
</style>