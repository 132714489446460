<template>
    <div class="c-modal">
        <div class="modal fade" ref="modal" tabindex="-1" :style=" show ? { 'display' : 'block', 'opacity': '1'} : { 'display' : 'none', 'opacity': '0'}">
            <div class="modal-dialog modal-dialog-centered">
                
                <form @submit.prevent="$emit('submit')">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel" v-show="titulo">{{ titulo }}</h1>
                            <button type="button" class="btn-close" @click="fechar" ></button>
                        </div>
                        <div class="modal-body">
                            <slot></slot>
                        </div>

                        <div class="modal-footer">
                            <slot name="rodape"></slot>
                        </div>
                    
                    </div>
                </form>
            </div>
        </div>

        <div class="modal-backdrop fade" :style=" show ? { 'display' : 'block', 'opacity': '0.5'} : { 'display' : 'none', 'opacity': '0'}" ref="backdrop"></div>
    </div>
</template>
<script>
export default {
    name: 'c-modal',
    props:{
        titulo: {
            type: String,
            default: 'Título'
        }
    },

    data:function(){
        return {
            show: false
        };
    },

    methods: {

        abrir(){
            this.show = true;
            this.$emit('abrir');
        },

        fechar(){
            this.show = false;
            this.$emit('fechar');
        }

    }
}
</script>

<style lang="scss" scoped>
   form{
    display: contents;
   }

</style>