<template>
    <div :class="{ 'c-produtos-lista' : true, 'd-flex' : loading, 'justify-content-center' : loading }">

        

        <div id="filtros"  v-show="!loading" class="row align-items-baseline justify-content-center padding-x2 padding-y1">
            <div class="col-1"><span>Filtre os Produtos</span></div>
            <div class="col-3">
                <select class="form-select" v-model="filtros.categoria" @change="filtrar">
                    <option value="" selected>Todas as categorias</option>
                    <option value="#0072C6">Bebidas</option>
                    <option value="#FDB813">Bomboniere</option>
                    <option value="#3D8E33">Comidas</option>
                    <option value="#FF00FF">Doces</option>
                    <option value="#993399">Outros</option>
                </select>
            </div>
            <div class="col-3">
                <select class="form-select" v-model="filtros.situacao" @change="filtrar">
                    <option value="D" selected>Somente produtos disponíveis</option>
                    <option value="T">Disponíveis e esgotados</option>
                </select>
            </div>
        </div>

        <div class="row padding-y5" v-show="loading" >
            <div class="col-12">
                <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-6 offset-3" v-show="produtos.length == 0 && !loading">
            <div class="alert alert-info margin-y2" role="alert">
                <h4 class="alert-heading">Oops! Nenhum produto encontrado 🙄</h4>
                <p>Neste momento, não temos nenhum produto disponível para você. Mas antes de desistir, vamos verificar se não é algum filtro que está impedindo você de encontrar o que procura? Tente limpar o campo de pesquisa no topo do site para testar.</p>
            </div>
        </div>

        <c-produtos-item :filtros="filtros"  v-for="(produto, index)  in produtos" :key="index" :cor="cores[index % cores.length]" :produto="produto" v-show="!loading" @recarregar="recarregar" />
        
    </div>

</template>

<script>
import { mapActions } from 'vuex'


export default {

    data: function(){
        return {
            cores: [ '#831f82', '#f7a700', '#009fe3', '#95c11f', '#e6007e', '#e94f0e', '#ffdd00', '#00478c', '#17a638' ],
            produtos: [],
            produtos_completo: [],
            filtros: {
                categoria: '',
                situacao: 'T',
                s: ''
            }
        }
    },

    mounted: async function(){

        await this.carrega_produtos();
      
    },

    methods: {

        ...mapActions('auth', {
            store_login: 'login'
        }),


        async carrega_produtos(){

            let produtos = await this.$sdk.get('/produtos', this.campos);
            
            //console.log('[PRODUTOS]', produtos.data);

            this.produtos = produtos.data;
            this.produtos_completo = this.produtos;
        },

        async recarregar(){
            await this.recarrega_usuario();
            await this.carrega_produtos();
            this.$emit('comprou');
        },

        async recarrega_usuario(){

            let token = localStorage.getItem('rocket_token');

            this.$sdk.token = token;
            let ret = await this.$sdk.get('/login');
            this.store_login({ usuario: ret.data.usuario, token: ret.data.token})
            console.log('Usuário recarregado - TOKEN', ret);

        },

        pesquisar(s){
            console.log('pesquisar', s)
            this.filtros.s = s;

            this.filtrar();
        },

        filtrar(){

            //se não tiver nada pra filrar, ignora (pra ganhar tempo)
            if(!this.filtros.s && !this.filtros.categoria && this.filtros.situacao != 'D'){
                return;
            }
            
            this.produtos = this.produtos_completo.filter((val) => {
             

                //filtra por palavra
                if(this.filtros.s){

                    let s = this.filtros.s;
                    
                    if(!val.Nome.toLowerCase().includes(s.toLowerCase())){
                        return false;
                    }
                    console.log('[S]', s);

                }

                //verifica se tem categoria selecionada
                if(this.filtros.categoria){
                    if(val.Cor != this.filtros.categoria){
                        return false;
                    }
                }

                //verifica se tem que filtrar só por disponiveis
                if(this.filtros.situacao == 'D'){
                    if(val.Disponivel <= 0){
                        return false;
                    }
                }

                return true;


            });
            

        }

    },

    computed: {

        loading: function(){
            return (!this.produtos_completo && Array.isArray(this.produtos_completo));
        }

    }

}
</script>

<style lang="scss" scoped>
    @import "@sass/vars.scss";

    .c-produtos-lista{
        display: flex;
        flex-wrap: wrap;
    }

    
    #filtros{
        width: 100%;
        span{
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            color: darken($cor-cinza, 20);
            text-align: center;
            line-height: 13px!important;
            display: block;
            top: -6px;
            position: relative;
        }
    }
</style>