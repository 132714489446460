<template>
    <div>
        <div class="modal fade" id="modalMenuAdm" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class=" modal-dialog modal-sm modal-dialog-centered">
                <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Painel de Administração</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p class="font-monospace text-center">{{ fraseSorteada }}</p>

                    <div class="d-grid gap-2">
                        <button class="btn btn-primary" ata-bs-toggle="modal" :data-bs-target="'#novo'">🥤 Adicionar produto</button>
                    </div>

                </div>
                
                </div>
            </div>
        </div>

        <c-produto-form />

    </div>
</template>

<script>

export default {

    data: function(){
        return {
            frases: [
                'Quem é rei, nunca perde a majestade.',
                'Rei uma vez, rei para sempre.',
                'Só cai a coroa de quem não sabe usá-la.',
                'A coroa pode sair da cabeça, mas nunca do coração.',
                'Não é a coroa que faz o rei, mas sim o caráter.',
            ]
        }
    },

    computed: {
        fraseSorteada() {
            return this.frases[Math.floor(Math.random() * this.frases.length)]
        }
    },
}
</script>
