<template>
     <div v-if="usuarios">

        <c-modal titulo="Gerenciamento de Usuários" ref="modal">
            <table class="table table-hover">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col"></th>
                    <th scope="col">Nome</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Ativo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(usuario, usuario_i) in usuarios" :key="usuario_i">
                        <th scope="row">
                            {{ usuario.ID }}
                        </th>
                        <td><span class="editar" @click="editar(usuario)">📝</span></td>
                        <td>{{ usuario.Nome }}</td>
                        <td>{{ pega_tipo(usuario.Tipo) }}</td>
                        <td>{{ pega_ativo(usuario.Ativo) }}</td>
                    </tr>

                </tbody>
            </table>

            <template v-slot:rodape>
                <button class="btn btn-primary" @click="novo">📝 Novo Usuário</button>
                <button type="button" class="btn btn-secondary fechar" @click="fechar">Fechar</button>
            </template>
        </c-modal>
           
        <c-modal-usuario-form ref="modal_form" :usuario="usuario_em_edicao" @fechar="abrir" />
        
    </div>

</template>
<script>

//import { Modal } from 'bootstrap';

export default {
    
    data: function(){
        return {
            show: false,
            usuarios: null,
            usuario_em_edicao: null
        }
    },

    mounted: function(){
        this.load();
    },

    methods: {
/**
        prepare(){
            console.log('Modal Usuario Lista montado!', this.$refs.modal);
            this.modal = new Modal(this.$refs.modal);
        },
**/
        async load(){

            let ret = await this.$sdk.get('/usuarios');

            if(ret.status == 200)
                this.usuarios = ret.data;
            else
                this.usuarios = null;

        },

        pega_tipo(sigla){

            if(sigla == 'ADM')
                return 'Administrador(a)';

            if(sigla == 'USR')
                return 'Colaborador(a)';
        },

        pega_ativo(letra){
            if(letra == 'S')
                return '✅';
            else
                return '🟨';
        },

        editar(usuario){

            if(usuario){
                this.usuario_em_edicao = usuario;
            } else {
                this.usuario_em_edicao = this.$store.state.auth.usuario;
            }

            this.fechar();
            setTimeout(() =>{
                this.$refs.modal_form.abrir();
            }, 100)
            
        },

        novo(){
            this.editar(null);
        },
        
        abrir(){
            this.load();
            this.$refs.modal.abrir();
        },

        fechar(){
            this.$refs.modal.fechar();
        }

    }

}
</script>

<style lang="scss" scoped>
    .editar{
        cursor: pointer;
    }

</style>