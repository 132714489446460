import Vue          from 'vue'
import axios        from 'axios'
import VueAxios     from 'vue-axios'
Vue.use(VueAxios, axios)

export default {

    uri : 'https://honestbar.agenciatempobrasil.com.br/api',
    prefix_images: 'https://admin.xxx.com.br/uploads/',
    parametros: [],
    token: null,
    
    async post(endpoint, params, headers){

        let $this = this;

        return new Promise((resolve) => {
        
            var url = $this.uri + endpoint;

            console.log('[API POST]', url);


            Vue.axios.post(url, params, { headers: Object.assign(this.headers(), headers ) })
            .then((ret) => { 
                
                //console.log('[API RESPONSE]', ret.data);
                resolve(ret.data);
                
            })
            .catch((error) => { 
                resolve({
                    status: 503,
                    mensagem: 'Erro de conexão com a API.',
                    slug: 'api.erro_conexao',
                    log: error
                });
            });
            
        })

    },

    async get(endpoint, params){

        return new Promise((resolve) => {

            var url = this.uri + endpoint;

            const queryString = new URLSearchParams(params).toString();
            url += '?' + queryString;

            console.log('[API GET]', url);

            Vue.axios.get(url, { headers: this.headers() })
            .then((ret) => { 
                
                //console.log('[API RESPONSE]', ret.data);
                resolve(ret.data);
                
            })
            .catch((error) => { 
                resolve({
                    status: 503,
                    mensagem: 'Erro de conexão com a API.',
                    slug: 'api.erro_conexao',
                    log: error
                });
            });

        })

        

    },

    prepare(){
        /**
        var $this = this;
        this.get('parametros', function(data){
            console.log('[data]', data)
            $this.parametros = data;
        })
        **/
    },

    headers(){

        if(this.token){
            return  {
                'token': this.token
            };
        } else {
            return {};
        }
          
    },
   
    /**
     * Instalar use no Vue.
     * 
     * @param {Vue} Instancia do Vue
     */
    install(Vue) {
        Vue.prototype.$sdk = this;
        Vue.sdk = this;

        this.prepare();
    }
};
