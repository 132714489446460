import Vue from 'vue'
import Router from 'vue-router'
import store from './stores';

Vue.use(Router)

//Importa rotas
import routes from "./routes"
const router = new Router({ 
    mode: 'history',
    base: '',
    routes
})


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        console.log('Está logado!');
      
        // Verifica se o usuário está autenticado
        if (!store.state.auth.estaLogado) {
            
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            })
            return
        }
      
    }
  
    next()
})

export default router