export default {
    namespaced: true,
  
    state: {
      usuario: null,
      token: null,
      estaLogado: false
    },
  
    mutations: {

      SET_USER(state, usuario) {
        state.usuario = usuario;
      },
      
      SET_TOKEN(state, token) {
        state.token = token;
      },
      
      SET_STATUS(state, estaLogado) {
        state.estaLogado = estaLogado;
      }

    },
  
    actions: {

      login({ commit }, pms) {
        commit('SET_USER', pms.usuario);
        commit('SET_TOKEN', pms.token);
        commit('SET_STATUS', true);
      },
      logout({ commit }) {
        commit('SET_USER', null);
        commit('SET_TOKEN', null);
        commit('SET_STATUS', false);
      }

    }
  };
  