<template>
    <div class="c-produtos-item padding-1">

        
        <c-modal-loading v-show="loading" />

        <div class="produto">

            <div class="thumb" :style="{ 'background-color' : produto.Cor }">
                <div class="mascara"  :style="{ 'background-image' : 'url('+ produto.Imagem +')' }"></div>
            </div>
            <h3>{{ produto.Nome }}</h3>
            <div class="preco">
                <small>R$</small>
                <span>{{ produto.Valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace(/^(\D+)/g, '') }}</span>
            </div>
            
            <footer v-if="produto.Ativo == 'S'">
                <div class="qtd" v-show="qtd > 0">
                    <button @click="valorMenos">-</button>
                    <span id="valor" v-text="valor.toString().padStart(2, '0')" :title="'Existe(m) ' + qtd + ' em estoque.'"></span>
                    <button @click="valorMais">+</button>
                </div>
                <div class="qtd" v-show="qtd <= 0">
                    <span class="badge rounded-pill" v-show="qtd <= 0" title="No momento, não temos nenhuma unidade deste produto.">INDISPONÍVEL NO MOMENTO</span>
                </div>
                <div class="d-flex justify-content-end align-items-center" v-show="qtd > 0">
                    <button class="btn btn-primary comprar" :disabled="pode_comprar" @click="comprar">comprar</button>
                </div>
            </footer>

            <footer v-if="produto && produto.Ativo != 'S'">
                <div class="alert alert-warning" role="alert">
                    Este arquivo está arquivado!
                </div>
            </footer>

            <div class="barra" v-if="$store.state.auth.usuario && $store.state.auth.usuario.Tipo == 'ADM'">
                <button class="btn editar" title="Atualizar estoque" data-bs-toggle="modal" :data-bs-target="'#produto_estoque_' + produto.ID"><i class="bi bi-cart4"></i></button>
                <button class="btn editar" title="Desarquivar este produto" @click="acao_desarquivar" v-show="produto.Ativo != 'S'"><i class="bi bi-archive-fill"></i></button>
                <button class="btn editar" title="Arquivar este produto" @click="acao_arquivar" v-show="produto.Ativo == 'S'"><i class="bi bi-archive"></i></button>
                <button class="btn editar" title="Editar este produto" data-bs-toggle="modal" :data-bs-target="'#produto_' + produto.ID"><i class="bi bi-pencil-square"></i></button>
            </div>
            

        </div>

        
        <c-produto-form :produto="produto" @salvou="atualizado" />
        <c-produto-estoque :produto="produto" @salvou="atualizado" />

    </div>
        
</template>
<script>
export default {

    props: {
        cor: {
            type: String,
            default: 'red'
        },
        produto: {
            type: Object,
            require: true
        }
    },

    data: function(){
        return {
            valor: 0,
            loading: false
        }
    },

    methods: {

        async acao_arquivar(){

            this.loading = true;

            let ret = await this.$sdk.post('/produto-arquivar', {
                ID: this.produto.ID
            });

            if(ret.status == 200){
                
                // mensagem de erro
                this.$swal.fire({
                    icon: 'success',
                    title: 'Produto arquivado 📦',
                    text: 'Pronto! 👍 Este produto foi arquivado e não está mais disponível para compra.'
                })
                
            } else {
                
                // mensagem de erro
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops! Tivemos um probleminha 🙄',
                    text: ret.mensagem
                })

            }

            this.loading = false;
            this.carrega_produtos();

        },

        async acao_desarquivar(){
            this.loading = true;

            let ret = await this.$sdk.post('/produto-desarquivar', {
                ID: this.produto.ID
            });

            if(ret.status == 200){
                
                // mensagem de erro
                this.$swal.fire({
                    icon: 'success',
                    title: 'Olha quem voltou! 🤩',
                    text: 'Pronto! ✅ Este produto foi desarquivado e já está disponível pra venda novamente.'
                })
                
            } else {
                
                // mensagem de erro
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops! Tivemos um probleminha 🙄',
                    text: ret.mensagem
                })

            }

            this.loading = false;
            this.carrega_produtos();

        },
        
        valorMais(){
            if(this.valor >= this.produto.Disponivel){
                this.$swal.fire({
                    icon: 'error',
                    allowOutsideClick: false,
                    title: 'Oops, parece que o sucesso foi maior do que o previsto 😅',
                    text: 'Desculpe, mas não temos tanto estoque assim de ' + this.produto.Nome + '. Temos somente mais ' + this.produto.Disponivel + ' unidade(s), ok?'
                })

            } else {
                this.valor += 1;
            }
            
        },
        valorMenos(){

            if(this.valor > 0){
                this.valor -= 1;
            }

        },

        async comprar(){
            this.loading = true;

            //alert(this.valor + ' | ' + this.produto.ID);

            
            let ret = await this.$sdk.post('/comprar', {
                produto: this.produto.ID,
                quantidade: this.valor
            });

            if(ret.status == 200){
                
                // mensagem de erro
                this.$swal.fire({
                    icon: 'success',
                    title: 'Já é seu 😍',
                    text: 'Pronto! 🥳 Sua compra foi realizada com sucesso e agora você pode retirá-la no Honest Bar.'
                })
                
            } else {
                
                // mensagem de erro
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops! Tivemos um probleminha 🙄',
                    text: ret.mensagem
                })

            }

            this.valor = 0;
            this.carrega_produtos();
            this.loading = false;

        },

        carrega_produtos(){
            this.$emit('recarregar');
        },

        atualizado(){
            this.carrega_produtos();
        }
    },

    computed: {
        pode_comprar: function(){
            return (this.valor <= 0);
        },

        qtd: function(){
            return (this.produto.Disponivel <= 0 ? 0 : this.produto.Disponivel);
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "@sass/vars.scss";

    .c-produtos-item{
        width: calc(100% / 3);
        max-width: calc(100% / 3);
        flex-grow: 1;
        position: relative;

        .barra{
            position: absolute;
            top: 35px;
            right: 30px;

            .btn.editar{
                color: $cor-branco;
                border: 0;
                padding: 0;
            }
        }
        

        .produto{
            background-color: $cor-cinza-claro;
            border-radius: 10px;
        }

        .thumb{
            border-radius: 10px 10px 0 0;
            height: 220px;
            display: flex;
            justify-content: center;
            align-items: center;

            .mascara{
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                width: 200px;
                height: 180px;

            }
        }

        h3{
            font-size: 18px;
            font-weight: 400;
            text-align: center;
            padding: 15px 5px;
            position: relative;
            margin-bottom: 15px;

            &:after{
                content: "";
                height: 2px;
                left: 30%;
                right: 30%;
                bottom: 0;
                background-color: #b4b4b4;
                position: absolute;
            }
        }

        .preco{
            text-align: center;
            color: $cor-roxo;
            font-weight: 800;
            font-size: 50px;

            small{
                font-size: 20px;
                margin: 2px;
                position: relative;
                top: -18px;
            }
        }

        footer{
            display: flex;
            padding: 15px;

            div{
                flex: 1;
            }
        }

        .qtd{
            display: flex;

            button{
                background-color: $cor-laranja;
                width: 30px;
                height: 30px;
                line-height: 30px;
                border-radius: 50%;
                border: 0;
                color: $cor-branco;
                font-size: 21px;
                font-weight: 800;
                margin: 5px;
                user-select: none;
            }

            #valor{
                border: 0;
                width: 50px;
                text-align: center;
                font-weight: 800;
                font-size: 23px;
                color: $cor-preto;
                background-color: $cor-cinza-claro;
            }

            .badge{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 15px;
                font-size: 11px;
                font-weight: 500;
                color: darken($cor-cinza-claro,40%);
                background-color: darken($cor-cinza-claro,10%);
            }
        }

        .comprar{
            background-color: $cor-roxo;
            border-color: $cor-roxo;
            line-height: 30px;
            padding: 0 30px;
            height: 30px;
            border-radius: 15px;
        }
    }

</style>