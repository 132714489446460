<template>    


    <c-modal :titulo="titulo" ref="modal" @fechar="$emit('fechar')" @submit="salvar">

        <div v-if="usuario_editavel">
                        
                <div class="mb-3">
                    <label for="nome" class="col-form-label">Nome:</label>
                    <input type="text" class="form-control" id="nome" v-model="usuario_editavel.Nome" required :readonly="loading">
                </div>
                <div class="mb-3">
                    <label for="email" class="col-form-label">E-mail:</label>
                    <input type="email" class="form-control" id="email" v-model="usuario_editavel.Email" required :readonly="loading || profile">
                </div>
                <div class="mb-3 col-6">
                    <label for="senha" class="col-form-label">Senha:</label>
                    <input type="password" class="form-control" id="senha" v-model="usuario_editavel.Senha" :readonly="loading">
                </div>       
                    
                <div class="row" v-if="!profile">

                    <div class="col-4">
                        <div class="mb-3">
                            <label for="senha" class="col-form-label">Ativo:</label>
                            <select class="form-select" v-model="usuario_editavel.Ativo" :disable="loading" required>
                                <option value="S">Sim</option>
                                <option value="N">Não</option>
                            </select>
                        </div>
                    </div>  
            
                    <div class="col-8">
                        <div class="mb-3">
                            <label for="senha" class="col-form-label">Tipo:</label>
                            <select class="form-select" v-model="usuario_editavel.Tipo" :readonly="loading" required>
                                <option value="ADM">Administrador</option>
                                <option value="USR">Colaborador</option>
                            </select>
                        </div>
                    </div>     
                    
                </div>
                
                <div class="mb-3">
                    <label for="avatar" class="col-form-label">Avatar:</label>
                    <input type="file" class="form-control" id="usuario_avatar" ref="avatar" accept="image/png, image/jpeg" :readonly="loading">
                </div>

        </div>
    
        <template v-slot:rodape>
            <button type="button" class="btn btn-secondary fechar" @click="fechar" :disabled="loading">Cancelar</button>
            <button type="submit" class="btn btn-primary" :disabled="loading">
                <div class="spinner-border spinner-border-sm" role="status" v-show="loading">
                    <span class="visually-hidden">Loading...</span>
                </div>
                {{ loading ? 'Aguarde...' : 'Salvar' }}
            </button>
        </template>

    </c-modal>

</template>

<script>

//import { Modal } from 'bootstrap';

export default {
    name: 'usuario-form',
    props: {
        usuario: {
            type: Object,
            default: null
        },
        profile: {
            type: Boolean,
            default: false
        }
    },

    data: function(){
        return {
            loading: false,
            usuario_editavel: null
        }
        
    },

    mounted: function(){
        this.reset();
    },

    methods: {

        reset(){

            if(this.usuario){
                this.usuario_editavel = JSON.parse(JSON.stringify(this.usuario));
                delete this.usuario_editavel.Avatar;
                this.usuario_editavel.Senha = null;
            } else {
                this.usuario_editavel = {
                    Ativo: 'S',
                    Email: null,
                    Nome: null,
                    Senha: null
                }
            }
            
        },

        async salvar(){

            this.loading = true;


            // Cria um objeto FormData
            const formData = new FormData();
            Object.entries(this.usuario_editavel).forEach(([key, value]) => {

                if(value == 'null'){
                    value = '';
                }

                formData.append(key, value);
            });

            if(this.$refs.avatar.files[0]){
                const file = this.$refs.avatar.files[0];
                formData.append('Avatar', file);
            }

            let ret = await this.$sdk.post('/usuario-editar', formData, { 'Content-Type' : 'multipart/form-data' });

            if(ret.status == 200){
                
                // mensagem de erro
                this.$swal.fire({
                    icon: 'success',
                    title: '🎉 usuario foi atualizado com sucesso! ',
                    text: 'Deu tudo certo e este usuario já foi atualizado. 🚀',
                }).then(() => {
                    this.$emit('salvou');
                })

                
            } else {
                
                // mensagem de erro
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops! Tivemos um probleminha 🙄',
                    text: ret.mensagem
                })

            }

            this.loading = false;

        },

        abrir(){
            this.reset();
            this.$refs.modal.abrir();
        },

        fechar(){
            this.$refs.modal.fechar();
        }

    },

    computed: {
        titulo(){
            if(this.usuario){
                return 'Editando usuário';
            } else {
                return 'Novo usuário';
            }
            
        }
    }


}
</script>