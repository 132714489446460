<template>    
    <div class="modal fade" ref="modal" :id="produto == null ? 'novo' : ('produto_estoque_' + produto.ID)" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <form>
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel" v-if="produto">Atualizando estoque de <strong>{{ produto.Nome }}</strong></h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        
                        <div class="mb-3">
                            <label for="nome" class="col-form-label">Valor:</label>
                            <input type="number" class="form-control" id="valor" v-model="campos.valor" min="0" max="100" step=".01" required>
                        </div>
                        <div class="mb-3">
                            <label for="valor" class="col-form-label">Adicionar no estoque (unidades):</label>
                            <input type="number" class="form-control" id="qtd" v-model="campos.qtd" min="1" max="100" step="1" required>
                            <div class="alert alert-warning" role="alert">
                            Estoque atual deste produto: {{ produto.Disponivel }}
                            </div>

                        </div>
                        
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary fechar" data-bs-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-primary" @click="salvar">Salvar </button>
                    </div>            
                </div>
            </form>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        produto: {
            type: Object,
            default: null
        }
    },

    data: function(){
        return {
            campos: {
                valor : '',
                qtd: 1,
            }
        }
        
    },

    mounted: function(){
        this.reset();

        this.$nextTick(() => {

            this.$refs.modal.addEventListener('shown.bs.modal', () => {
                this.reset();
            });

        })
    },

    methods: {

        reset(){

            if(this.produto){
                this.campos.valor = this.produto.Valor;
                this.campos.qtd = 1;
            }
            
        },

        async salvar(){
            
            this.$refs.modal.querySelector('.fechar').click();

            let ret = await this.$sdk.post('/produto-estoque', {
                ID: this.produto.ID,
                Valor: this.campos.valor,
                Quantidade: this.campos.qtd
            });

            if(ret.status == 200){
                
                // mensagem de erro
                this.$swal.fire({
                    icon: 'success',
                    title: '🛍️🎉 Estoque atualizado com sucesso! ',
                    text: 'Deu tudo certo o estoque deste produto foi atualizado.'
                })

                this.$emit('salvou');
                
            } else {
                
                // mensagem de erro
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops! Tivemos um probleminha 🙄',
                    text: ret.mensagem
                })

            }
            
        },
        

    }


}
</script>