<template>

    <div>

        <section id="cabecalho" class="d-flex justify-content-center">

            <header>
                <div class="container">
                    <div class="row">
                        <div class="col-4 offset-4">
                            <div id="formulario-busca">
                                <input type="text" id="s" class="form-control" placeholder="Pesquisa aqui" v-model="s" @input="pesquisando" >
                                <input type="button" id="btn" value="Pesquisar">
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <footer>
                <div class="container">
                    <div class="row">
                        <div class="col-5 d-flex align-items-center">
                            <div id="perfil" v-if="$store.state.auth.usuario">
                                <div :style="{ 'background-Image' : 'url(' + avatar + ')'}"  id="avatar" :title="'Avatar do(a) ' + $store.state.auth.usuario.Nome"></div>
                                <span id="nome">Olá, {{ $store.state.auth.usuario.Nome.split(" ")[0] }}</span>
                            </div>
                        </div>
                        <div class="col-4 offset-3">
                            
                            <div class="infos" v-if="$store.state.auth.usuario">

                                    <div class="info" title="Esse valor representa o total que você já consumiu hoje." >
                                        <span class="titulo">Gasto do Dia</span>
                                        <span class="valor" >{{ $store.state.auth.usuario.SaldoDia | currency }}</span>
                                    </div>

                                    <div class="info" title="Esse valor representa o total que você já consumiu este mês e será descontado no seu próximo pagamento.">
                                        <span class="titulo">Gasto do Mês</span>
                                        <span class="valor">{{ $store.state.auth.usuario.SaldoMes | currency }}</span>
                                    </div>

                            </div>

                        </div>
                        
                    </div>
                </div>
            </footer>

        </section>

        <section id="produtos">
            
            <div class="container">

                <div class="row">
                    <div class="col-12">
                        <c-produtos-lista ref="lista" @comprou="comprou" />
                    </div>
                </div>
            </div>

        </section>

        <!-- Funções Administrativas -->
        <div class="toolbar">
            <button id="adm" title="Cancelar compra" @click="modal_cancelar_compra" v-if="$store.state.auth.usuario && $store.state.auth.usuario.Tipo == 'ADM'" ><img :src="require('@/assets/images/icone-cancelar.png')"></button>
            <button id="adm" title="Relatório de Consumo de Colaboradores" @click="modal_relatorios" v-if="$store.state.auth.usuario && $store.state.auth.usuario.Tipo == 'ADM'" ><img :src="require('@/assets/images/icone-relatorio.png')"></button>
            <button id="adm" title="Gerenciar usuários" @click="modal_usuarios" v-if="$store.state.auth.usuario && $store.state.auth.usuario.Tipo == 'ADM'" ><img :src="require('@/assets/images/icone-usuarios.png')"></button>
            <button id="adm" title="Adicionar novo produto" data-bs-toggle="modal" data-bs-target="#novo" v-if="$store.state.auth.usuario && $store.state.auth.usuario.Tipo == 'ADM'" ><img :src="require('@/assets/images/icone-mais.png')"></button>
            <button id="adm" title="Atualize o seu perfil" @click="modal_editar_perfil"><img :src="require('@/assets/images/icone-usuario.png')"></button>
            <button @click="sair">Sair</button>
        </div>

        <c-produto-form />
        <c-modal-relatorios ref="modal_relatorios" />
        <c-modal-usuarios-lista ref="modal_usuarios_lista" />
        <c-modal-usuario-form ref="modal_usuario_form" :usuario="store_usuario" :profile="true" @salvou="atualizar_pagina"/>
        <c-modal-cancelar ref="modal_cancelar" />

        <!-- Modal -->
        <c-modal-adm />

    </div>

</template>

<script>

import { mapActions } from 'vuex'
import { mapState } from 'vuex'

export default {

    data: function(){
        return {
            s: ''
        }
    },

    methods: {

        ...mapActions('auth', {
            store_logout: 'logout'
        }),

        sair(){

            //apaga token do registro local
            localStorage.removeItem('rocket_token');

            //salva usuario no store
            this.store_logout();

            // alega o usuário pro login
            this.$router.push({ name: 'login'});
        },

        adm(){

            alert('adm');

        },

        pesquisando(){

            this.$refs.lista.pesquisar(this.s);

        },

        comprou(){
            this.s =  '';
        },

        modal_usuarios(){
           this.$refs.modal_usuarios_lista.abrir();
        },

        modal_relatorios(){
           this.$refs.modal_relatorios.abrir();
        },

        modal_editar_perfil(){
            this.$refs.modal_usuario_form.abrir();
        },

        modal_cancelar_compra(){
            this.$refs.modal_cancelar.abrir();
        },

        atualizar_pagina(){
            document.location.reload();
        }

    },

    computed: {
        
        ...mapState('auth', {
            store_usuario: 'usuario'
        }),

        avatar(){
            if(this.$store.state.auth.usuario && this.$store.state.auth.usuario.Avatar){
                return this.$store.state.auth.usuario.Avatar;
            } else {
                return require('@/assets/images/avatar.png');
            }
            
        }
    }

}

</script>

<style lang="scss">
    @import "@sass/vars.scss";
    
    section#cabecalho{
        background-image: url('~@images/banner.png');
        background-repeat: no-repeat;
        background-position: center, top;
        height: 560px;
        background-color: $cor-azul;
        position: relative;

        header{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            padding: 25px 0;
            
        }

        footer{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            padding: 25px 0;
        }

        #formulario-busca{
            width: 100%;
            position: relative;
            height: 70px;
            display: flex;
            align-items: center;

            input#s{
                width: 100%;
                height: 63px;
                line-height: 63px;
                border-radius: 32px;
                padding-left: 80px;
                font-size: 20px;
                font-weight: 500;
                background-color: $cor-cinza-claro;
            }

            input#btn {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                background-color: #b80e80;
                background-image: url('~@/assets/images/icone-lupa.png');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 50%;
                color: #fff;
                font-size: 16px;
                font-weight: bold;
                text-transform: uppercase;
                text-align: center;
                line-height: 70px;
                border: none;
                cursor: pointer;
                text-indent: -9999px;
                left: 0;
                top: 0;
                position: absolute;
            }
        }

        #perfil{
            display: flex;
            align-items: center;
            
            #avatar{
                margin-right: 15px;
                width: 100px;
                height: 100px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                border-radius: 50%;
            }
            #nome {
                font-size: 36px;
                font-weight: 800;
                color: $cor-branco;
            }
        }

       .infos{
            display: flex;
            .info{
                flex: 1;
                padding: 15px 25px;

                .titulo {
                    display: block;
                    text-align: center;
                    font-weight: 500;
                    font-size: 16px;
                    color: $cor-branco;
                }
                .valor{
                    text-align: center;
                    display: block;
                    background-color: $cor-cinza-claro;
                    border-radius: 25px;
                    height: 40px;
                    line-height: 40px;
                    font-weight: 600;
                    font-size: 20px;
                }
            }
       }


    }

    .toolbar{
            
        position: fixed;
        right: 10px;
        top: 0;

        button{
            border: 0;
            background: transparent;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 700;
            padding: 5px 7px;
            z-index: 99999;

            &#adm{

                img{
                    width: 20px;
                    position: relative;
                    top: -2px;
                }
            }
        }
    }


</style>