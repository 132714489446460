import Dash         from "./pages/dash"
import Login        from "./pages/login"

import SistemaDash        from "./pages/sistema/dash"


const routes = [
    {  
      path: '/',
      redirect: '/dash'
    },
    {
        name: 'dash',
        path: '/dash',
        component: Dash
    },
    {
        name: 'login',
        path: '/login',
        component: Login
    },

    {
        name: 'sistema-dash',
        path: '/sistema/dash',
        redirect: '/sistema',
        component: SistemaDash
    },

  ]

  export default routes