<template>
    <div class="page sistema">

        <section id="cabecalho-login" class="padding-y1">
            <div class="container">
                <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                        <img src="@/assets/images/logo-tempobrasil.png">
                    </div>
                </div>
            </div>
        </section>

        <section id="form-login">
            <div class="container">
                
                <div class="row">
                    <div class="col-4 offset-4 margin-y2">

                        <form @submit.prevent="logar">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">Faça a sua identificação</h5>
                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control" :readonly="loading" v-model="campos.email" required>
                                        <label for="floatingInput">E-mail</label>
                                    </div>
                                    <div class="form-floating">
                                        <input type="password" class="form-control" :readonly="loading" v-model="campos.senha" ref="senha" required>
                                        <label for="floatingPassword">Senha</label>
                                    </div>
                                    <div class="d-grid gap-2 col-6 mx-auto margin-t1"> 
                                        <button type="submit" class="btn btn-primary btn-lg margin-y1 d-flex justify-content-center" :disabled="loading"> <span v-text="(!loading ? 'Entrar' : 'Entrando...')"></span>  <i class="bi bi-arrow-right" v-show="!loading"></i><i class="spinner-border spinner-border-sm" role="status" aria-hidden="true"  v-show="loading"></i></button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="alert alert-danger alert-dismissible fade show margin-t1" role="alert" v-if="$route.query.msg">
                            {{  $route.query.msg }}
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>

                    </div>
                </div>
               
            </div>
        </section>

        <section id="rodape-login">
            <div class="container">
                <div class="row">
                    <div class="col-6 offset-3">
                        <p class="text-center font-monospace">© Copyright © 2023 Todos os direitos reservados.<br>
                        Sistema desenvolvido por <a href="http://www.produtorarocket.com" target="_blank">Rocket Produtora Digital</a>.</p>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    
    data: function() {
        return {
            loading: false,
            campos: {
                email: '',
                senha: ''
            }
        }
    },

    mounted(){

        // verifica se tem algum e-mail salvo no cash e insere ele
        if(localStorage.getItem('login_email') !== null){
            this.campos.email =  localStorage.getItem('rocket_email');
        }

        //verifica se já está logado

        if(this.$store.state.auth.estaLogado){
            this.$router.push({ name: 'dash' });
        }
        this.$watch(() => this.$store.state.auth.estaLogado, (novo_valor) => {
            
            console.log('Está logado?', novo_valor);
            if(novo_valor){
                this.$router.push({ name: 'dash' });
            }

        })
        

    },

    methods: {
        ...mapActions('auth', {
            store_login: 'login'
        }),

        async logar(){
            console.log('Login');
            this.loading = true;

            let ret = await this.$sdk.post('/login', this.campos);

            //salva e-mail no localStorage
            localStorage.setItem('rocket_email', this.campos.email);
            

            if(ret.status == 200){
                
                /** LOGOU **/
                
                //salve token no localStorage
                localStorage.setItem('rocket_token', ret.data.token);

                //salva usuario no store
                this.store_login({ usuario: ret.data.usuario, token: ret.data.token });

                //força atualizar o token do $sdk
                this.$sdk.token = ret.data.token;

                // limpa o formulário
                this.limpa_formulario();

                // abre página do dash
                this.$router.push({ name: 'dash'});

            } else {

                /** ERRO DE LOGIN **/

                // mensagem de erro
                await this.$swal.fire({
                    icon: 'error',
                    title: 'Erro de identificação',
                    text: ret.mensagem
                })                

                this.$refs.senha.focus();

                // limpa o formulário
                this.limpa_formulario(); 

            }
                
        },

        limpa_formulario(){
            this.loading = false;
            this.campos.senha = '';
        }

       
    }

}
</script>

<style lang="scss">

    #cabecalho-login{
        background-color: var(--cor-rosa);
        
    }

    #form-login{
        .card-title{
            text-align: center;
            text-transform: uppercase;
            font-size: 14px;
            padding: 4px 0;
        }
    }

    #rodape-login{
        font-size: 12px;
        color: var(--bs-secondary);
    }
</style>