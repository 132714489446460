import Vue      from 'vue'

import c_produtos_lista  from './produtos-lista.vue';
import c_produtos_item  from './produtos-item.vue';
import c_produto_form  from './produto-form.vue';
import c_produto_estoque  from './produto-estoque.vue';

import c_modal_spinner  from './modal-loading.vue';
import c_modal_adm  from './modal-adm.vue';
import c_modal_usuarios_lista  from './usuarios-lista.vue';
import c_modal_usuario_form  from './usuario-form.vue';
import c_modal_relatorios  from './modal-relatorios.vue';
import c_modal_cancelar  from './modal-cancelar.vue';

import c_modal  from './modal.vue';

Vue.component('c-produtos-lista',           c_produtos_lista);
Vue.component('c-produtos-item',            c_produtos_item);
Vue.component('c-produto-form',             c_produto_form);
Vue.component('c-produto-estoque',          c_produto_estoque);
Vue.component('c-modal-loading',            c_modal_spinner);
Vue.component('c-modal-adm',                c_modal_adm);
Vue.component('c-modal-usuarios-lista',     c_modal_usuarios_lista);
Vue.component('c-modal-usuario-form',       c_modal_usuario_form);
Vue.component('c-modal-relatorios',         c_modal_relatorios);
Vue.component('c-modal-cancelar',         c_modal_cancelar);
Vue.component('c-modal',                    c_modal);

