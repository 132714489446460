<template>    
    <div class="modal fade" ref="modal" :id="produto == null ? 'novo' : ('produto_' + produto.ID)" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <form>
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel" v-if="produto">Editando produto <strong>{{ produto_editavel.Nome }}</strong></h1>
                        <h1 class="modal-title fs-5" id="exampleModalLabel" v-if="!produto">Novo produto</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        
                        <div class="mb-3">
                            <label for="nome" class="col-form-label">Nome:</label>
                            <input type="text" class="form-control" id="nome" v-model="produto_editavel.Nome" required>
                        </div>

                        <div class="row">

                            <div class="col-6">
                            <div class="mb-3">
                                <label for="valor" class="col-form-label">Valor:</label>
                                <input type="number" class="form-control" id="valor" v-model="produto_editavel.Valor" min="0" max="100" step=".01" required>
                            </div>    
                        </div>

                        <div class="col-6">
                            <div class="mb-3">
                                <label for="cor" class="col-form-label">Categoria:</label>
                                <select class="form-select" id="cor" required v-model="produto_editavel.Cor">
                                    <option value="#0072C6">Bebidas</option>
                                    <option value="#FDB813">Bomboniere</option>
                                    <option value="#3D8E33">Comidas</option>
                                    <option value="#FF00FF">Doces</option>
                                    <option value="#993399">Outros</option>
                                </select>
                            </div>    
                        </div>


                        </div>
                        
                        
                        <div class="mb-3">
                            <label for="produto_imagem" class="col-form-label">Foto:</label>
                            <input type="file" class="form-control" id="produto_imagem" ref="imagem" accept="image/png, image/jpeg">
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary fechar" data-bs-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-primary" @click="salvar">Salvar </button>
                    </div>            
                </div>
            </form>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        produto: {
            type: Object,
            default: null
        }
    },

    data: function(){
        return {
            produto_editavel: {
                Nome : '',
                Valor: 0,
                Imagem: null
            }
        }
        
    },

    mounted: function(){
        this.reset();

        this.$nextTick(() => {

            this.$refs.modal.addEventListener('shown.bs.modal', () => {
                this.reset();
            });

        })
    },

    methods: {

        reset(){

            if(this.produto){
                this.produto_editavel = JSON.parse(JSON.stringify(this.produto));
                delete this.produto_editavel.Imagem;
            }
            
        },

        async salvar(){

            this.$refs.modal.querySelector('.fechar').click();

             // Cria um objeto FormData
             const formData = new FormData();
            Object.entries(this.produto_editavel).forEach(([key, value]) => {
                formData.append(key, value);
            });

            if(this.$refs.imagem.files[0]){
                const file = this.$refs.imagem.files[0];
                formData.append('Imagem', file);
            }

            let ret = await this.$sdk.post('/produto-editar', formData, { 'Content-Type' : 'multipart/form-data' });

            if(ret.status == 200){
                
                // mensagem de erro
                this.$swal.fire({
                    icon: 'success',
                    title: '🎉 Produto foi atualizado com sucesso! ',
                    text: 'Deu tudo certo e este produto já foi atualizado. 🚀'
                })

                this.$emit('salvou');
                
            } else {
                
                // mensagem de erro
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops! Tivemos um probleminha 🙄',
                    text: ret.mensagem
                })

            }
        },

        arquivo_enviado(e){
            const file = e.target.files[0];
            console.log('[file]', file);
            const fileSize = file.size / 1024 / 1024; //tamanho em MB

            if (!['image/png', 'image/jpeg'].includes(file.type)) {
               alert('Apenas arquivos PNG e JPG são permitidos');
               e.target.value = '';
                return;
            }

                    
            if (fileSize > 1) {
                alert('O tamanho máximo do arquivo é 1MB');
                e.target.value = '';
                return;
            }

            const reader = new FileReader();

            reader.onload = () => {
                this.produto_editavel.Imagem = reader.result;
            };
            reader.readAsDataURL(file);
        }

        

    }


}
</script>